<template>
  <VuePerfectScrollbar
    ref="requestInputList"
    class="request-input-list scroll-area"
    :settings="settings"
  >
    <RequestInputForm
      v-for="(request, index) in requests"
      :key="index"
      :letter="alphabet[index]"
      :is-removable="index >= 2"
      :request="request"
      :addresses-disabled="addressesDisabled"
      @remove="$emit('remove', index)"
      @change="$emit('change', request)"
    ></RequestInputForm>
  </VuePerfectScrollbar>
</template>

<script>
import RequestInputForm from './RequestInputForm';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
export default {
  props: {
    addressesDisabled: {
      type: Boolean,
      default: false,
    },
    requests: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    alphabet: 'abcdefghijklmnopqrstuvwxyz'.split(''),
    settings: {
      maxScrollbarLength: 70,
      // wheelSpeed: 0.6,
    },
  }),

  components: {
    RequestInputForm,
    VuePerfectScrollbar,
  },
  methods: {
    scrollToEnd() {
      setTimeout(() => {
        const container = this.$refs.requestInputList;

        container.scrollTop = container.scrollHeight;
        container.update();
      }, 100);
    },
  },
  updated() {},
};
</script>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  max-height: 400px;
}
</style>
