<template>
  <div class="request-input-form">
    <div class="vx-row flex flex-grow-1 ml-2">
      <div
        :id="letter + '-div-with-loading'"
        class="vx-col w-1/12 request-input-form__letter vs-con-loading__container"
      >
        {{ letter | capitalize }}
      </div>

      <div class="vx-col w-10/12">
        <div class="vx-row">
          <div v-if="!addressesDisabled" class="vx-col w-full mb-3">
            <ValidationProvider
              rules="required"
              name="ciudad"
              v-slot="{ errors }"
            >
              <vs-select
                name="Ciudad"
                :data-test="'citySelect-' + letter"
                label="Ciudad"
                placeholder="Selecciona la ciudad"
                class="w-full mt-2"
                v-model="selectedCity"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :data-test="`citySelectItem-${letter}-${index}`"
                  :text="item.city.name"
                  v-for="(item, index) in coverageCities"
                />
              </vs-select>
              <span v-if="errors.length" class="text-danger text-sm">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
          <div class="vx-col w-full mb-3">
            <ValidationProvider
              rules="required"
              name="dirección"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                :data-test="'addressInput-' + letter"
                placeholder="Dirección"
                @keyup="typing"
                :disabled="addressesDisabled"
                :success="calculatingSuccess"
                :danger="calculatingFailure || !!errors.length"
                :danger-text="errors[0]"
                v-model="localRequest.address"
              ></vs-input>
            </ValidationProvider>
          </div>
          <div class="vx-col w-full mb-3">
            <ValidationProvider
              rules="required"
              name="descripción"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                :data-test="'descriptionInput-' + letter"
                placeholder="Descripción"
                :disabled="false"
                v-model="localRequest.description"
                :danger="calculatingFailure || !!errors.length"
                :danger-text="errors[0]"
              ></vs-input>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <feather-icon
        v-if="isRemovable"
        @click="$emit('remove', request)"
        icon="TrashIcon"
        svgClasses="stroke-current hover:text-danger"
      ></feather-icon>
    </div>
    <vs-divider></vs-divider>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  watch,
  ref,
} from '@vue/composition-api';
import { useGeocoder } from '@/composable/useGeocoder.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import { COVERAGES } from '@/graphql/queries';
import _ from 'lodash';
export default defineComponent({
  props: {
    letter: {
      type: String,
      default: '',
    },
    request: {
      type: Object,
      default: () => {},
    },
    isRemovable: {
      type: Boolean,
      default: true,
    },
    addressesDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const {
      waitForTyping,
      geoCode,
      isCalculatingAddress,
      calculatingSuccess,
      calculatingFailure,
    } = useGeocoder(emit, root);
    const { result: coveragesResult } = useQuery(COVERAGES);
    const coverageCities = useResult(coveragesResult, (data) => data.coverages);

    const selectedCity = ref();

    const localRequest = computed({
      get() {
        return props.request;
      },
      set(value) {
        emit('change', value);
      },
    });

    watch(isCalculatingAddress, (value) => {
      if (value) {
        root.$vs.loading({
          container: '#' + props.letter + '-div-with-loading',
          scale: 0.6,
        });
      } else {
        root.$vs.loading.close(
          '#' + props.letter + '-div-with-loading > .con-vs-loading'
        );
      }
    });

    const resetCalculationValidations = () => {
      calculatingFailure.value = false;
      calculatingSuccess.value = false;
    };

    watch(
      () => props.request.address,
      () => {
        if (!props.request.coordinates.lat && !props.request.coordinates.lng) {
          typing();
          resetCalculationValidations();
        } else {
          calculatingSuccess.value = true;
        }
      }
    );

    watch(selectedCity, (value) => {
      if (value.city) {
        console.log(
          '🚀 ~ file: RequestInputForm.vue ~ line 173 ~ watch ~ value',
          value
        );
        console.log(
          '🚀 ~ file: RequestInputForm.vue ~ line 174 ~ watch ~ localRequest.value',
          localRequest.value
        );
        localRequest.value.city = _.omit(value.city, ['__typename']);
        emit('change', localRequest.value);
      }
      typing();
    });

    const typing = () => {
      if (selectedCity.value) {
        console.log(selectedCity.value.city.name);
        waitForTyping().then(async () => {
          const position = await geoCode(
            localRequest.value.address,
            selectedCity.value.city.name || 'bucaramanga'
          );

          localRequest.value.coordinates = {
            lat: position.lat,
            lng: position.lng,
          };
          emit('change', localRequest.value);
        });
      }
    };

    onMounted(() => {});

    return {
      localRequest,
      typing,
      calculatingSuccess,
      calculatingFailure,
      coverageCities,
      selectedCity,
    };
  },
});
</script>

<style lang="scss">
.request-input-form {
  &__letter {
    @apply bg-link-water
  text-slate-gray
  flex justify-center
  items-center rounded-lg;
  }
}
</style>
