import { gmapApi } from 'vue2-google-maps';
import _ from 'lodash';
import { computed, reactive, toRefs } from '@vue/composition-api';

export function useGeocoder(emit, root) {
  const state = reactive({
    searchQueryIsDirty: false,
    timeout: null,
    isCalculatingAddress: false,
    position: {},
    calculatingSuccess: false,
    calculatingFailure: false,
    formattedAddress: '',
  });
  const google = computed(() => gmapApi());

  function success() {
    emit('success');
    state.calculatingSuccess = true;
    state.calculatingFailure = false;
  }
  function fail() {
    emit('fail');
    state.calculatingFailure = true;
    state.calculatingSuccess = false;
  }
  const waitForTyping = () => {
    return new Promise((resolve) => {
      clearTimeout(state.timeout);
      state.searchQueryIsDirty = true;

      state.timeout = setTimeout(() => {
        state.searchQueryIsDirty = false;
        resolve();
      }, 500);
    });
  };
  const geoCode = _.throttle(function (searchText, city) {
    return new Promise((resolve, reject) => {
      if (searchText) {
        state.isCalculatingAddress = true;

        setTimeout(() => {
          root.$repositories.geocoder
            .getAddress(searchText, city)
            .then(({ data }) => {
              if (data.status === 'success') {
                const position = {
                  lat: data.data.coordinates[1],
                  lng: data.data.coordinates[0],
                };

                state.position = position;
                state.isCalculatingAddress = false;
                resolve(position);
                success();
                emit('position');
              }
            })
            .catch((e) => {
              fail();
              reject(e);
              state.isCalculatingAddress = false;
            });
        }, 2000);
      } else {
        state.isCalculatingAddress = false;
      }
    });
  }, 1000);

  const geocodeLatLng = _.throttle(function (latlng) {
    return new Promise((resolve, reject) => {
      state.isCalculatingAddress = true;
      setTimeout(() => {
        const geocoder = new google.value.maps.Geocoder();
        geocoder.geocode(
          {
            location: latlng,
            region: 'CO',
          },
          (results, status) => {
            if (status === 'OK') {
              state.formattedAddress = results[0].formatted_address;
              state.isCalculatingAddress = false;
              success();
              resolve(results[0].formatted_address);
            } else {
              state.isCalculatingAddress = false;
              fail();
              reject(new Error('Geocoder failed due to: ' + status));
              // console.log('Geocoder failed due to: ' + status)
            }
          }
        );
      }, 2000);
    });
  }, 1000);

  return {
    google,
    state,
    geoCode,
    geocodeLatLng,
    waitForTyping,
    ...toRefs(state),
  };
}
